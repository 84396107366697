@use "sass:math";

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == large-tablet-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == large-desktop-up {
    @media (min-width: 1536px) {
      @content;
    }
  }
}
