$border-size: 5px;
$pod-desktop-size: 275px;

#photos {
  display: flex;
  flex-direction: column;
}

#photos h2 {
  letter-spacing: 0.4px;
  color: #fff;
  margin: 0 0 20px 0;
}

.gallery-wrapper {
  margin: 25px 0;
  max-width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, $pod-desktop-size + $border-size * 2);
}

.gallery-item {
  width: $pod-desktop-size;
}

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: $border-size solid #dcf3f5;
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.2);
}

@include for-size(phone-only) {
  .gallery-wrapper {
    justify-content: center;
  }

  .gallery-item {
    width: 100%;
  }
}
