/* --- containers --- */
.main-container {
  padding: 90px 12% 100px 12%;
  position: relative;
  overflow: hidden;

  @include for-size(phone-only) {
    padding: 70px 12% 70px 12%;
  }
}

.section {
  padding: 90px 24px 100px;
}

/* --- decorations --- */
.heading-with-flair::before {
  content: "";
  position: absolute;
  width: 75px;
  height: 3px;
  left: 0px;
  top: 40px;
  background-color: $indigo-dark;
}
