#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background-color: $primary-0;
  height: 3px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}
