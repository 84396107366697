/* === Colors === */
/* primary */
$primary-1000: #f6fafe;
$primary-900: #ecf6fe;
$primary-800: #d7ecfe;
$primary-700: #a2dfff;
$primary-600: #6dd1ff;
$primary-500: #18a0fb;
$primary-400: #1295ee;
$primary-300: #0072c7;
$primary-200: #0251a5;
$primary-100: #033082;
$primary-0: #031a6b;

/* accents */
$cyan-main: #9fedf5;
$cyan-ligt: #bef2f9;
$cyan-dark: #91d7df;
$indigo-main: #d0d0ff;
$indigo-light: #dcdcff;
$indigo-dark: #bdbde8;
$purple-main: #44419a;
$purple-light: #6462ab;
$purple-dark: #38367f;
$green-main: #66bb6a;
$green-light: #81c784;
$green-dark: #388e3c;
$red-main: #c91f1f;
$red-light: #ea8d8d;
$red-dark: #a61111;
$yellow-main: #ffb400;
$yellow-light: #ffd160;
$yellow-dark: #e8a400;

/* neutrals */
$neutral: #5d5e5f;
$neutral-100: #0c1218;
$neutral-200: #1a242e;
$neutral-300: #2a3641;
$neutral-400: #3d4751;
$neutral-500: #6f7880;
$neutral-600: #9da3aa;
$neutral-700: #c9cfd4;
$neutral-800: #dee3e8;
$neutral-900: #ebf0f4;
$neutral-1000: #f6fafe;

$primary: $primary-500;
$neutral: $neutral-500;
$success: $green-main;
$danger: $red-main;
$warning: $yellow-main;

/* Typography */
$font-primary: "Sofia Pro", "Fira Sans", "Droid Sans", "-apple-system",
  "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu",
  "Cantarell", "Helvetica Neue", sans-serif;
$font-secondary: "Iowan Old Style", "Apple Garamond", "Baskerville",
  "Times New Roman", "Droid Serif", "Times", "Source Serif Pro", serif;

/* z-indexes */
$z-0: 0;
$z-10: 10;
$z-20: 20;
$z-30: 30;
$z-40: 40;
$z-50: 50;
$z-auto: auto;
