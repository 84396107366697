.info-card {
  background-color: #fff;
  outline: 2.5px dashed #fff;
  margin: 85px auto 50px;
  box-shadow: 5px 6px 20px 0px rgba(0, 0, 0, 0.15);

  &__item {
    width: 100%;
    display: flex;
    position: relative;
    border-bottom: 2px dashed #97d3fb;

    &::before {
      content: "";
      position: absolute;
      top: 45%;
      left: 40%;
      width: 25%;
      height: 25px;
      background-image: url("../../images/sender-to-receiver-arrow.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center right;
      z-index: $z-10;

      @include for-size(tablet-up) {
        top: 40%;
        left: 45%;
        width: 125px;
      }
    }
  }
}
