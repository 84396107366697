@font-face {
  font-family: "Sofia Pro";
  font-weight: 900;
  src: url("../fonts/Mostardesign - Sofia Pro Black.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 700;
  src: url("../fonts/Mostardesign - Sofia Pro Bold.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 600;
  src: url("../fonts/Mostardesign - Sofia Pro Semi Bold.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 500;
  src: url("../fonts/Mostardesign - Sofia Pro Medium.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 400;
  src: url("../fonts/Mostardesign - Sofia Pro Regular.otf");
}

@font-face {
  font-family: "Sofia Pro";
  font-weight: 300;
  src: url("../fonts/Mostardesign - Sofia Pro Light.otf");
}
