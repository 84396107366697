/* --- atomic classes --- */
/* NOTE: these were moved over and cleaned up from app.scss. These should be completely replaced by Tailwind when we overhaul the style system in the future. */
.text-danger {
  color: $danger;
}

.text-primary {
  color: $primary;
}

.text-primary-dark {
  color: $primary-0;
}

.text-xs {
  font-size: rem(12px);
}

.font-sans {
  font-family: $font-primary;
}

.font-serif {
  font-family: $font-secondary;
}

.font-normal {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.mt-1 {
  margin-top: 14px;
}

.mt-2 {
  margin-top: 28px;
}

.mt-1\/2 {
  margin-top: 7px;
}

.mb-1 {
  margin-bottom: 14px;
}
