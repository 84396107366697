.star {
  background-image: url("../../images/star-normal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
  list-style-type: none;
  margin: auto 3px;
}
