/* TODO: the styles below were ported over from app.scss in the interest of time, and slightly cleaned up but they need to be be purged and refactored completely */
/* There's way too much specificity pollution and repetition here - not maintainable. */
$step-icon-size: 26px;
$step-icon-size--large: 36px;

.shipping-steps {
  width: 100%;
  margin: 35px 0 0 0;
  display: flex;
  flex-direction: column;

  @include for-size(tablet-up) {
    margin: 0;
  }
}

.step-info {
  position: relative;
  display: flex;
  padding-bottom: 25px;
  flex-direction: row;

  @include for-size(tablet-up) {
    padding-bottom: 50px;
  }

  &::before {
    content: "";
    width: 1px;
    height: 100%;
    background: none;
    position: absolute;
    top: 0px;
    left: 28px;
    z-index: 0;
    border-left: 2px dashed $primary-0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 62px;
    left: 23px;
    border: solid $primary-0;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  }

  &--completed {
    &::before {
      opacity: 0.9;
    }

    &::after {
      opacity: 1;
    }
  }

  &--recent {
    &::before {
      opacity: 0.3;
    }

    &::after {
      opacity: 0.3;
    }
  }

  &--next {
    &::before {
      opacity: 0.3;
    }

    &::after {
      opacity: 0.3;
    }
  }

  &--final {
    padding-bottom: 0px;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

.step-icon {
  flex: 0 0 auto;
  position: relative;
  width: $step-icon-size;
  height: $step-icon-size;
  padding: 15px;
  background: #fff;
  border-radius: 100%;
  border: 2px solid transparent;
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__icon {
    width: 25px;
    font-size: rem(25px);
  }

  &--completed {
    border-color: $success;

    &::before {
      content: "\2713";
      width: 12px;
      height: 16.5px;
      background: $success;
      position: absolute;
      top: 0px;
      left: $step-icon-size + $step-icon-size / 1.5;
      z-index: $z-30;
      border-radius: 100%;
      color: #fff;
      line-height: 0;
      font-size: rem(22px);
      display: inline-flex;
      align-items: center;
      margin: auto;
      padding: 4px 9px 2px 2px;
    }
  }

  &--failed {
    border-color: $danger;

    &::before {
      content: "X";
      width: 14px;
      height: 14px;
      background: $danger;
      position: absolute;
      top: 0px;
      left: $step-icon-size + $step-icon-size / 1.5;
      z-index: $z-30;
      border-radius: 100%;
      color: #fff;
      line-height: 0;
      font-size: rem(15px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      padding: 5px;
    }
  }

  &--problem {
    border-color: $warning;

    &::before {
      content: "?";
      width: 14px;
      height: 14px;
      background: $warning;
      position: absolute;
      top: 0px;
      left: $step-icon-size + $step-icon-size / 1.5;
      z-index: $z-30;
      border-radius: 100%;
      color: $primary-100;
      line-height: 0;
      font-size: rem(15px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      padding: 5px;
    }
  }

  &--next &__img,
  &--next &__icon {
    opacity: 0.4;
  }

  &--next {
    border-color: $neutral-900;

    &::before {
      display: none;
    }
  }
}

.step--short {
  width: 100%;

  @include for-size(tablet-up) {
    width: 25%;
  }

  & .step-info {
    padding-bottom: 45px;
    flex-direction: row;
    align-items: center;

    @include for-size(tablet-up) {
      flex-direction: column;
      padding-bottom: 0;
    }

    &::before {
      content: "";
      background: none;
      position: absolute;
      width: 3px;
      height: 80%;
      top: -$step-icon-size--large * 2;
      left: 28px;
      z-index: $z-0;
      border-left: 2px dashed $primary-0;

      @include for-size(tablet-up) {
        width: 100%;
        height: 1px;
        top: $step-icon-size--large + 2px;
        left: 50%;
        border-top: 2px dashed $primary-0;
        border-left: none;
      }
    }

    &::after {
      display: none;
    }

    &--completed {
      margin-top: 0;
    }

    &--recent {
      margin-top: 0;
    }

    &--next {
      margin-top: 0;
    }

    &--final {
      @include for-size(tablet-up) {
        margin-top: 0;
        padding-right: 0;
      }
    }

    &--failed {
      &::before {
        display: block;
        opacity: 1;
        content: "!";
        background: $danger;
        position: absolute;
        z-index: 1000;
        border: 0px;
        border-radius: 100px;
        color: #fff;
        text-align: center;
        font-family: $font-primary;
        font-weight: 900;
        width: 17.5px;
        height: 17px;
        top: calc(100% - 25px);
        left: 19.5px;
        font-size: 16px;
        line-height: 16px;
      }

      @include for-size(tablet-up) {
        &::before {
          display: block;
          opacity: 1;
          content: "!";
          width: 22.5px;
          height: 22px;
          background: $danger;
          position: absolute;
          top: 25px;
          left: -10px;
          z-index: 1000;
          border: 0px;
          border-radius: 100px;
          color: #fff;
          font-size: 20px;
          text-align: center;
          font-family: $font-primary;
          font-weight: 900;
          line-height: 22px;
        }
      }
    }
  }

  & .step-text {
    text-align: left;

    @include for-size(tablet-up) {
      text-align: center;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 0;
      display: block;
    }
  }

  & .step-icon {
    margin-bottom: 0;
    margin-right: 24px;

    @include for-size(tablet-up) {
      height: $step-icon-size--large;
      width: $step-icon-size--large;
      padding: 20px;
      margin-right: 0;
      margin-bottom: 12px;

      &::before {
        left: $step-icon-size--large + $step-icon-size--large / 1.5;
      }
    }
  }
}

.step-text {
  align-self: center;

  &__title {
    margin: 0px 0px 5px 0px;
    display: inline-block;
    position: relative;
  }

  &--next {
    opacity: 0.4;
  }
}

.summary {
  display: flex;
  width: 90%;
  margin: 35px auto;
  flex-direction: column-reverse;

  @include for-size(tablet-up) {
    margin: 85px auto 55px;
    flex-direction: row;
    width: 100%;
  }
}
