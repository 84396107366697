/* --- backgrounds --- */
.gradient-light-blue {
  background: rgb(151, 211, 251);
  background: linear-gradient(
    120deg,
    rgba(151, 211, 251, 1) 0%,
    rgba(220, 243, 245, 1) 75%
  );
}

.gradient-vibrant-blue {
  background: rgb(24, 160, 251);
  background: linear-gradient(
    120deg,
    rgba(24, 160, 251, 1) 0%,
    rgba(64, 98, 234, 1) 75%
  );
}

.bg-graphic-left {
  position: absolute;
  left: -150px;
  bottom: 0;
  opacity: 0.5;
  overflow: hidden;

  &__asset {
    position: relative;
    transform: rotate(-50deg);
    width: 270px;
  }

  @include for-size(tablet-up) {
    left: -65px;
    top: 0;
    transform: none;

    &__asset {
      transform: none;
      width: 85%;
    }
  }

  @include for-size(desktop-up) {
    left: 0;
    opacity: 0.7;
    z-index: -1;

    &__asset {
      width: 115%;
    }
  }
}

.bg-graphic-right {
  position: absolute;
  top: -110px;
  transform: rotate(-10deg);
  right: -50px;
  overflow: hidden;
  opacity: 0.3;

  &__asset {
    position: relative;
    right: -150px;
    width: 340px;
    vertical-align: middle;
    display: inline-block;
  }

  @include for-size(tablet-up) {
    top: 30%;
  }

  @include for-size(desktop-up) {
    opacity: 0.7;
    z-index: -1;

    &__asset {
      right: -195px;
      transform: rotate(40deg);
      width: 100%;
    }
  }
}

.bg-neutral-900 {
  background-color: $neutral-900;
}
