.button-purple {
  border: 1px solid $purple-light;
  background-color: $purple-light;
  color: $primary-1000;

  &:hover {
    background-color: $primary-1000;
    color: $purple-main;
    border-color: $purple-main;
  }

  &--inverted {
    border: 1px solid $primary-1000;
    background-color: $primary-1000;
    color: $purple-main;

    &:hover {
      background-color: $purple-light;
      color: $primary-1000;
      border-color: $purple-light;
    }
  }
}
