.icon--truck {
  display: inline;
  object-fit: scale-down;
  width: 75px;
  margin: 0;
  margin-left: 8px;

  @include for-size(tablet-up) {
    width: 85px;
  }

  @include for-size(desktop-up) {
    width: 95px;
  }
}
